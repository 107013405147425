import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

// Update the element with the current year
const currentYear = new Date().getFullYear();

document.addEventListener("contextmenu", function (e) {
  e.preventDefault();
  e.stopImmediatePropagation();
});

document.addEventListener("dragstart", function (e) {
  e.preventDefault();
});

// document.addEventListener("selectstart", function (e) {
//   e.preventDefault();
// });

document.addEventListener("copy", function (e) {
  e.preventDefault();
});

document.getElementById("year").innerText =
  `© ${currentYear} Cade Gore. All rights reserved.`;
